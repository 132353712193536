@import url('//fonts.googleapis.com/css2?family=Lato:wght@400&display=swap');

body {
    margin: 0 !important;
}

.bg_fabric {
    background-image: url("../imgs/fabric-of-squares.png") !important;
    min-height: 100vh !important;
}

.ant-btn:active, .ant-btn:hover, .ant-btn:focus {
    border-color: transparent !important;
}

/*.ant-btn {*/
/*    padding: 10px 25px !important;*/
/*    height: auto !important;*/
/*    border: transparent !important;*/
/*    border-radius: 2px !important;*/
/*}*/

/*.ant-layout-sider-trigger {*/
/*background: #ffb74d !important;*/
/*}*/

/*.ant-layout-sider-trigger > .anticon svg {*/
/*color: black !important;*/
/*}*/

.ant-layout-sider-collapsed > .ant-layout-sider-children > .user_info {
    visibility: hidden !important;
    opacity: 0 !important;
    transition: visibility 0s linear 200ms, opacity 200ms !important;
}

.user_info {
    margin-top: 30px;
    padding: 0 10px !important;
    visibility: visible !important;
    opacity: 1 !important;
    transition: visibility 0s linear 0s, opacity 600ms !important;
}

.user_data {
    font-size: 18px;
    font-family: Arial, sans-serif;
    margin: 3px 0;
}

.nav_divider {
    margin: 8px 0 !important;
}

.pointer_btn_txt {
    cursor: pointer !important;
}

.base_content_box_view {
    margin: 1rem 2rem;
}

.base_content_box_col {
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 4px;
    height: fit-content;
}

.menu_overwrite > li {
    padding-left: 0 !important;
}

.menu_overwrite > li > div {
    padding-left: 0 !important;
    padding-inline: 0 !important;
    font-size: 15px;
}

.menu_overwrite > li > span > a {
    align-items: center;
    display: flex;
}

.menu_overwrite > li > ul > li > span > a {
    align-items: center;
    display: flex;
}

.menu_overwrite > li > div > span > a {
    align-items: center;
    display: flex;
}

.menu_icon {
    font-size: 20px !important;
}

.submenu {
    padding-left: 10px;
}
